import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle
} from "@fluentui/react-components";

export interface ConfiguringAccessDialogProps {
  isOpen: boolean;
  onContinueClick: () => void;
  onCancelClick: () => void;
}

export const ConfiguringAccessDialog = (props: ConfiguringAccessDialogProps)  => (
  <Dialog open={props.isOpen}>
    <DialogSurface>
      <DialogBody>
        <DialogTitle>
          Congratulations!
        </DialogTitle>
        <DialogContent>
          We have received the necessary permits. To make <strong>ITP Assistant</strong> available to your tenant’s users, <a href='/ITPartnerAssistant_setup.pdf' target="_blank">configure</a> the appropriate access to them.
        </DialogContent>
        <DialogActions>
          <Button appearance="outline" onClick={props.onCancelClick}>Cancel</Button>
          <Button appearance="primary" onClick={props.onContinueClick}>Continue</Button>
        </DialogActions>
      </DialogBody>
    </DialogSurface>
  </Dialog>
)
