import {
  tokens,
  makeStyles,
  DataGridBody,
  DataGridRow,
  DataGrid,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridCell,
  TableCellLayout,
  TableColumnDefinition,
  createTableColumn,
  TableColumnSizingOptions,
  shorthands
} from "@fluentui/react-components";
import { useNavigate } from "react-router-dom";
import { HistoryItem, OfferStatus, UserRole } from "../models";
import { NavigateButton } from "./buttons";

var useStyles = makeStyles({
  table: {
    backgroundColor: tokens.colorNeutralBackground1,
    marginTop: '20px',
  },
  tableBody: { // scrollable table content with fixed header
    maxHeight: "max(200px, calc(100vh - 160px))",
    ...shorthands.overflow("auto")
  },
  tableCell: {
    display: 'block',
    width: '100%',
    wordWrap: 'break-word',
    whiteSpace: 'pre-wrap'
  }
});

export type HistoryFeedTableProps = {
  items: HistoryItem[];
  mode: UserRole;
};

export const HistoryFeedTable = (props: HistoryFeedTableProps) => {
  const navigate = useNavigate();
  const styles = useStyles();

  const createNumberColumn = (columnId: string, header: string, sortable?: boolean) => {
    return createTableColumn<HistoryItem>({
      columnId: columnId,
      compare: sortable ? (a, b) => {
        if (!a[columnId]) return 1;
        if (!b[columnId]) return -1;
        return a[columnId] > b[columnId] ? 1 : a[columnId] < b[columnId] ? -1 : 0;
      } : undefined,
      renderHeaderCell: () => {
        return header;
      },
      renderCell: (item) => {
        return (
          <TableCellLayout className={styles.tableCell}>
            {item[columnId]}
          </TableCellLayout>
        );
      },
    });
  }

  const createTextColumn = (columnId: string, header: string, sortable?: boolean) => {
    return createTableColumn<HistoryItem>({
      columnId: columnId,
      compare: sortable ? (a, b) => {
        if (!a[columnId]) return 1;
        if (!b[columnId]) return -1;
        return a[columnId].localeCompare(b[columnId]);
      } : undefined,
      renderHeaderCell: () => {
        return header;
      },
      renderCell: (item) => {
        return (
          <TableCellLayout className={styles.tableCell}>
            {item[columnId]}
          </TableCellLayout>
        );
      },
    });
  }
  
  const createDateColumn = (columnId: string, header: string, sortable?: boolean) => {
    return createTableColumn<HistoryItem>({
      columnId: columnId,
      compare: sortable ? (a, b) => {
        if (!a[columnId]) return 1;
        if (!b[columnId]) return -1;
        return new Date(a[columnId]).getTime() - new Date(b[columnId]).getTime();
      } : undefined,
      renderHeaderCell: () => {
        return header;
      },
      renderCell: (item) => {
        return (
          <TableCellLayout className={styles.tableCell}>
            {item[columnId] ? new Date(item[columnId]).toLocaleString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit'}) : ''}
          </TableCellLayout>
        );
      },
    });
  }

  const createActionColumn = (columnId: string, header: string) => {
    return createTableColumn<HistoryItem>({
      columnId: columnId,
      renderHeaderCell: () => {
        return header;
      },
      renderCell: (item) => {
        return (
          <TableCellLayout className={styles.tableCell}>
            {(item.status === OfferStatus.waitingForDecision || item.status === OfferStatus.redirected) && 
            <NavigateButton onClick={() => navigate(`/license-order/${item.id}/consider/`)} />}
          </TableCellLayout>
        );
      },
    });
  }

  const columnDefinition = [
    {id: 'requestId', type: 'number', header: 'Request ID', visibility: 'itpEngineer', sortable: true},
    {id: 'requestDate', type: 'date', header: 'Request Date', visibility: 'all', sortable: true},
    {id: 'tenantName', type: 'text', header: 'Tenant Name', visibility: 'itpEngineer', sortable: true},
    {id: 'tenantId', type: 'text', header: 'Tenant ID', visibility: 'itpEngineer', sortable: true},
    {id: 'requester', type: 'text', header: 'Requester', visibility: 'globalAdmin,itpEngineer', sortable: true},
    {id: 'requestText', type: 'text', header: 'Request text', visibility: 'all'},
    {id: 'offer', type: 'text', header: 'Offer by ITP Engineer', visibility: 'all'},
    {id: 'statusName', type: 'text', header: 'Request Status', visibility: 'all', sortable: true},
    {id: 'decisionMakerComment', type: 'text', header: 'Decision Maker Comment', visibility: 'all'},
    {id: 'decisionMaker', type: 'text', header: 'Decision Maker', visibility: 'all', sortable: true},
    {id: 'decisionDate', type: 'date', header: 'Decision Date', visibility: 'all', sortable: true},
    {id: 'redirect', type: 'action', header: '', visibility: 'globalAdmin', sortable: true},
  ]

  const columns: TableColumnDefinition<HistoryItem>[] = columnDefinition.filter(column => {
    if (column.visibility === 'all') return true;
    return column.visibility.includes(props.mode);
  })
  .map((column) => {
    switch (column.type) {
      case 'number':
        return createNumberColumn(column.id, column.header, column.sortable);
      case 'text':
        return createTextColumn(column.id, column.header, column.sortable);
      case 'date':
        return createDateColumn(column.id, column.header, column.sortable);
      case 'action':
        return createActionColumn(column.id, column.header);
      default:
        throw new Error(`Unknown column type: ${column.type}`);
    }
  });

  const columnSizingOptions: TableColumnSizingOptions = {
    requestId: {
      minWidth: 75
    },
    requestDate: {
      minWidth: 100
    },
    tenantName: {
      minWidth: 100
    },
    tenantId: {
      minWidth: 120,
      defaultWidth: 200
    },
    requester: {
      minWidth: 140,
      defaultWidth: 200
    },
    requestText: {
      minWidth: 200,
      defaultWidth: 300
    },
    offer: {
      minWidth: 200,
      defaultWidth: 300
    },
    status: {
      defaultWidth: 100
    },
    decisionMakerComment: {
      minWidth: 200,
      defaultWidth: 300
    },
    decisionMaker: {
      minWidth: 140,
      defaultWidth: 200
    },
    decisionDate: {
      minWidth: 100
    },
    redirect: {
      minWidth: 50
    }
  }

  return (
    <DataGrid
      className={styles.table}
      columns={columns}
      sortable
      items={props.items}
      resizableColumns
      columnSizingOptions={columnSizingOptions}
    >
      <DataGridHeader>
        <DataGridRow>
          {({ renderHeaderCell }) => (
            <DataGridHeaderCell>
              <strong>{renderHeaderCell()}</strong>
            </DataGridHeaderCell>
          )}
        </DataGridRow>
      </DataGridHeader>
      <DataGridBody<HistoryItem> className={styles.tableBody}>
        {({ item, rowId }) => (
          <DataGridRow<HistoryItem>
            key={rowId}
          >
            {({ renderCell }) => (
              <DataGridCell>{renderCell(item)}</DataGridCell>
            )}
          </DataGridRow>
        )}
      </DataGridBody>
    </DataGrid>
  )
};