import {
  makeStyles,
  shorthands,
  tokens,
  Text,
  Caption1, Card,
  CardHeader,
  Button,
  PopoverTrigger,
  PopoverSurface,
  Popover
} from "@fluentui/react-components";
import { MoreHorizontal20Regular } from "@fluentui/react-icons";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles({
  card: {
    height: "fit-content"
  },
  caption: {
    color: tokens.colorNeutralForeground3,
  },
  logo: {
    ...shorthands.borderRadius("4px"),
    width: "48px",
    height: "48px",
  },
  text: {
    ...shorthands.margin(0),
  },
  popover: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.padding("5px", "0px"),
  }
});

export const LicenseAssistantAppCard = ({ img }) => {
  const navigate = useNavigate();
  const styles = useStyles();

  return (
    <Card
      className={styles.card}
      onClick={() => navigate("../license-order")}
    >
      <CardHeader
        image={
          <img
            className={styles.logo}
            src={img}
            alt="App logo"
          />
        }
        header={<Text weight="semibold">License Assistant</Text>}
        description={<Caption1 className={styles.caption}>IT Partner LLC</Caption1>}
        action={
          <Popover positioning="below-end">
            <PopoverTrigger disableButtonEnhancement>
              <Button
                appearance="transparent"
                icon={<MoreHorizontal20Regular />}
                aria-label="More options"
                onClick={(event: React.MouseEvent) => {
                  event.stopPropagation();
                }}
              />
            </PopoverTrigger>
            <PopoverSurface className={styles.popover}>
              <Button
                appearance="subtle"
                onClick={(event: React.MouseEvent) => {
                  navigate("../history-feed");
                  event.stopPropagation();
                }}
              >
                History Feed
              </Button>
              <Button
                appearance="subtle"
                onClick={(event: React.MouseEvent) => {
                  navigate("../license-order");
                  event.stopPropagation();
                }}
              >
                Create Request
              </Button>
            </PopoverSurface>
          </Popover>
        }
      />

      <p className={styles.text}>
        Use our Assistant to manage your licenses and services
      </p>
    </Card>
  );
};