import {
  makeStyles,
  shorthands,
  tokens,
  Text,
  Caption1,
  mergeClasses,
  CardProps,
  Card,
  CardHeader,
  CardHeaderProps
} from "@fluentui/react-components";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles({
  card: {
    height: "fit-content"
  },

  caption: {
    color: tokens.colorNeutralForeground3,
  },

  logo: {
    ...shorthands.borderRadius("4px"),
    width: "48px",
    height: "48px",
  },

  text: {
    ...shorthands.margin(0),
  }
});

export interface AppCardProps extends CardProps {
  title: string,
  description: string,
  img: string,
  action?: CardHeaderProps["action"],
  href?: string,
  page?: string
}

export const AppCard = ({ className, ...props }: AppCardProps) => {
  const navigate = useNavigate();
  const styles = useStyles();

  const onClick = () => {
    if (props.page) {
      navigate(props.page);
    }
    else if (props.href) {
      window.open(props.href, "_blank");
    }
  }

  return (
    <Card
      {...props}
      className={mergeClasses(className, styles.card)}
      onClick={onClick}
    >
      <CardHeader
        image={
          <img
            className={styles.logo}
            src={props.img}
            alt="App logo"
          />
        }
        header={<Text weight="semibold">{props.title}</Text>}
        description={<Caption1 className={styles.caption}>IT Partner LLC</Caption1>}
        action={props.action}
      />

      <p className={styles.text}>
        {props.description}
      </p>
    </Card>
  );
};