import { Button, ButtonProps, Tooltip } from "@fluentui/react-components";
import { ArrowSyncCircleRegular } from '@fluentui/react-icons';

export const ResetButton = (props: ButtonProps) => {
  return (
    <Tooltip content="Restore filters" relationship="label">
      <Button
        appearance="transparent"
        size="large"
        {...props}
        icon={<ArrowSyncCircleRegular />}
      />
    </Tooltip>
  );
}