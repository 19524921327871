import { Button, MessageBar, MessageBarActions, MessageBarBody, MessageBarIntent, MessageBarTitle } from "@fluentui/react-components";
import { DismissRegular } from "@fluentui/react-icons";

export interface NotificationBarProps {
  className?: string
  intent: MessageBarIntent
  title?: string
  description?: string
  onDismiss: () => void
}

export const NotificationBar = (props: NotificationBarProps) => {
  return (
    <MessageBar className={props.className} key={props.intent} intent={props.intent}>
      <MessageBarBody style={{overflow: "hidden", textOverflow: "ellipsis"}}>
        <MessageBarTitle>{props.title}</MessageBarTitle>
        {props.description}
      </MessageBarBody>
      <MessageBarActions
        containerAction={
          <Button onClick={props.onDismiss} appearance="transparent" icon={<DismissRegular />} />
        }
      ></MessageBarActions>
    </MessageBar>
  );
}