import axios from "axios";
import { Auth } from "../utils/auth";
import { IdNameDto } from "../models";
import config from "../config";

export const loadTenants = async () => {
  const token = await Auth.getToken([]);
  return await axios.get<IdNameDto[]>(`${config.infoboardBackendUrl}/tenants/all`, {
    headers: {
      Authorization: `Bearer ${token.token}`
    }
  })
};

export const loadInfoboard = async() => {
  const token = await Auth.getToken([]);
  return await axios.get(`${config.infoboardBackendUrl}/messages/infoboard`, {
    headers: {
      Authorization: `Bearer ${token.token}`
    }
  });
}