import { Dialog, DialogSurface, DialogBody, DialogTitle, Button, DialogContent } from "@fluentui/react-components";
import { AnalyticsRequestSuccessDialogProps } from "./AnalyticsRequestSuccessDialogProps";
import { Dismiss24Regular } from "@fluentui/react-icons";

export const AnalyticsRequestSuccessDialog = (props: AnalyticsRequestSuccessDialogProps) => {
	const { isOpen, setDialogOpen } = props;
	return (
		<Dialog open={isOpen}>
		<DialogSurface>
			<DialogBody>
				<DialogTitle
					action={
						<Button
							appearance="subtle"
							aria-label="close"
							icon={<Dismiss24Regular />}
							onClick={() =>
								setDialogOpen(false)
							}
						/>
					}
				>
					Your request has been sent successfully!
				</DialogTitle>
				<DialogContent>
					Since the tenant analysis is performed according to
					a number of rules, it will take some time to prepare
					the report. As soon as the report is generated, it
					will be sent to your email.
				</DialogContent>
			</DialogBody>
		</DialogSurface>
	</Dialog>
	);
}