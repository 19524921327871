import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, Text } from "@fluentui/react-components";
import { Warning24Regular } from "@fluentui/react-icons";
import config from "../../config";

export interface GrantingPermissionsDialogProps {
  tenantId: string;
  isOpen: boolean;
  close: (success: boolean) => void;
}

export const GrantingPermissionsDialog = (props: GrantingPermissionsDialogProps) => {
  const { tenantId, isOpen, close } = props;
  
  const onGrantingClick = () => {
    const redirectUri = `${config.migrationReportsBackendUrl}/tenants/register`;
    const adminConsentUrl = `https://login.microsoftonline.com/${tenantId}/adminconsent?client_id=${config.migrationReportsClientId}&redirect_uri=${redirectUri}&state=itpAssistant`;
    const size = 600;
    const top = (window.screen.height - size) / 2;
    const left = (window.screen.width - size) / 2;
    const newWindow = window.open(adminConsentUrl, "auth", `width=${size},height=${size},top=${top},left=${left}`);
    const timer = setInterval(function () {
      if (newWindow.closed) {
        clearInterval(timer);
        close(false);
      }
      try {
        if (newWindow.location?.origin === window.location.origin) {
          clearInterval(timer);
          newWindow.close();
          close(true);
        }
      } catch (e) {
        console.debug("Cannot read popup window location due to cross-origin policy");
      }
    }, 1000);
  }

  return (
    <Dialog open={isOpen}>
      <DialogSurface>
        <DialogBody>
          <DialogTitle style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}>
            <Warning24Regular style={{marginRight: "5px"}}/>
            <span>Tenant not registered</span>
          </DialogTitle>
          <DialogContent>
            <Text>
              We need access to your tenant in order to initiate the analysis.
              Please, grant us permission to read the data. Ensuring the security and protection of customer data is our highest value.
              Therefore, IT Partner uses its own analytical engine, and all calculations are performed in our environment.
              Data collection and processing will take some time.
            </Text>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => close(false)}>
              Close
            </Button>
            <Button 
              onClick={onGrantingClick}
              appearance="primary"
            >
              Grant permissions
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  )
};