import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogSurface,
  DialogTitle
} from "@fluentui/react-components";

export type SendOfferDialogProps = {
  open: boolean;
  onDeclineClick: () => void;
  onConfirmClick: () => void;
};

export const SendOfferDialog = (props: SendOfferDialogProps) => {
  return (
    <Dialog open={props.open}>
      <DialogSurface>
        <DialogBody>
          <DialogTitle>Do you want to send the offer?</DialogTitle>
          <DialogActions>
            <Button appearance="secondary" onClick={props.onDeclineClick}>No</Button>
            <Button appearance="primary" onClick={props.onConfirmClick}>Yes</Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  )
}