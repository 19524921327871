import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle
} from "@fluentui/react-components";

export interface AdminGrantingDialogProps {
  isOpen: boolean;
  onGrantingClick: () => void;
  onCancelClick: () => void;
}

export const AdminGrantingDialog = (props: AdminGrantingDialogProps) => (
  <Dialog open={props.isOpen}>
    <DialogSurface>
      <DialogBody>
        <DialogTitle>
          Almost there!
        </DialogTitle>
        <DialogContent>
          Please, ask your global admins to provide additional permissions for <strong>License Assistant</strong>. We need them to read basic tenant data.
        </DialogContent>
        <DialogActions>
          <Button appearance="outline" onClick={props.onCancelClick}>Cancel</Button>
          <Button appearance="primary" onClick={props.onGrantingClick}>Continue</Button>
        </DialogActions>
      </DialogBody>
    </DialogSurface>
  </Dialog>
)