/* eslint "no-useless-rename": "off", "react-hooks/exhaustive-deps": "off" */
import { Button, buttonClassNames, Card, makeStyles, Spinner, Text } from "@fluentui/react-components";
import { ArrowDownloadFilled, CalculatorRegular, Home20Regular, PlayRegular } from "@fluentui/react-icons";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { useIsGlobalAdmin } from "../apis/GraphApi";
import { getExcelReport, useGetDataCollectionResult, useRunDataCollection } from "../apis/MigrationServiceApi";
import { PageLayout } from "../components";
import { AccessDeniedAlert } from "../components/dialogs/AccessDeniedAlert/AccessDeniedAlert";
import { GrantingPermissionsDialog } from "../components/dialogs/GrantingPermissionsDialog";
import { MessageInfo } from "../MessageContext";
import { Auth } from "../utils/auth";

const useStyles = makeStyles({
  card: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minWidth: "740px"
  },
  cardBody: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "12px",
    width: "333px",
    color: "#979797",
  },
  homeButton: {
    marginLeft: 'auto'
  },
  explanationText: {
    minHeight: "40px",
    display: "flex",
    flexDirection: "column-reverse"
  },
  button: {
    marginBottom: "28px",
    boxShadow: "0px 4px 4px #00000025",
    fontSize: "20px",
    ":disabled": {
      backgroundColor: "#C0C0C0",
      color: "#FFFFFF"
    },
    ":disabled:hover": {
      backgroundColor: "#C0C0C0",
      color: "#FFFFFF",
      [`&:disabled > .${buttonClassNames.icon}`]: {
        color: "#FFFFFF",
      },
    },
    ":disabled:hover:active": {
      backgroundColor: "#C0C0C0",
      color: "#FFFFFF"
    },
    [`&:disabled > .${buttonClassNames.icon}`]: {
      color: "#FFFFFF",
    },
  }
});

export const MigrationReportPage = () => {
  const navigate = useNavigate();
  
  const styles = useStyles();
  const [message, setMessage] = useState<MessageInfo>(null);
  const [loaded, setLoaded] = useState(false);
  const [wasRunning, setWasRunning] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [tenantId, setTenantId] = useState("");
  
  // get user tenant id
  useEffect(() => {
    const credentials = Auth.getCredential();
    credentials.getUserInfo().then((userInfo) => {
      setTenantId(userInfo.tenantId);
    });
  });

  const queryClient = useQueryClient();
  const { data: isGlobalAdmin } = useIsGlobalAdmin();
  const { data: dataCollectionResult, status: status, isFetching: isFetchingReport } = 
    useGetDataCollectionResult(!!isGlobalAdmin, (error: AxiosError) => {
      setMessage({
        intent: "error",
        title: "Failed to get last analysis result",
        description: error.message
      });
    });
  const mutation = useRunDataCollection((error: AxiosError<any>) => {
    if (error.response?.status === 403) { // backend checked permissions
      setIsDialogOpen(true);
    }
    else {
      setMessage({
        intent: "error",
        title: "Failed to start data collection",
        description: error.message
      });
    }
  });

  const isRunning = dataCollectionResult?.status === "running" || mutation.isLoading;
  const hasReport = !isRunning && dataCollectionResult?.report;

  // Disable loader if the user is not global admin or data collection result is fetched
  useEffect(() => {
    if (!loaded && (isGlobalAdmin === false || status === "success" || status === "error")) {
      setLoaded(true);
    }
  }, [loaded, isGlobalAdmin, status]);

  // Continuously fetch the data collection report until it's finished
  useEffect(() => {
    if (isFetchingReport || dataCollectionResult?.status !== "running") 
      return;
    setTimeout(() => queryClient.invalidateQueries({queryKey: ["dataCollectionResult"]}), 1000);
  }, [isFetchingReport, dataCollectionResult]);

  useEffect(() => {
    if (wasRunning) {
      if (dataCollectionResult?.status === "failed") {
        setMessage({
          intent: "error",
          title: "Data collection failed",
          description: dataCollectionResult.error
        });
      } 
      else if (dataCollectionResult?.status === "completed") {
        setMessage({
          intent: "success",
          description: "Analysis completed. Please check"
        });
      }
    }
    setWasRunning(dataCollectionResult?.status === "running");
  }, [dataCollectionResult]);

  // Event handlers
  const onRunAnalysisClick = async () => {
    if (isRunning) return;
    mutation.mutate(tenantId);
  }

  const onEstimateMigrationCostsClick = () => {
    let url = "https://o365hq.com/directlink?q=";
    const properties = [
      { name: 'usersAndMailboxesCount', code: '_ITPWW170MIGOT' },
      { name: 'usersAndMailboxesCountWithArchiveCount', code: '_ITPWW495MIGOT' },
      { name: 'sharepointSitesWithNoTeamsCount', code: '_ITPWW340MIGOT' },
      { name: 'sharepointSitesWithTeamsCount', code: '_ITPWW370MIGOT' },
    ];

    const report = dataCollectionResult.report;
    properties.forEach(property => {
      if (report && report[property.name] > 0) {
        url += `${property.code}.${report[property.name]}`;
      }
    });
    
    window.open(url, "_blank");
  }

  const onDownloadClick = () => {
    getExcelReport(dataCollectionResult.id)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        const fileName = `${response.headers['content-disposition'].split('filename=')[1].split(';')[0]}`;
        link.setAttribute(
          "download",
          fileName
        );
        document.body.appendChild(link);
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .catch((error: AxiosError) => {
        setMessage({
          intent: "error",
          title: "Failed to download file",
          description: error.message
        });
      });
  }

  const onGrantingClose = (success: boolean) => {
    setIsDialogOpen(false);
    if (success) {
      // Message bar for successful registration
      setMessage({
        intent: "success",
        description: "Tenant registered successfully"
      });
    }
  }

  if (!loaded) {
    return (
      <PageLayout message={message} onDismiss={() => setMessage(null)}>
        <Spinner size="large"/>
      </PageLayout>
    )
  }

  const date = new Date(dataCollectionResult?.startedAt).toLocaleDateString();

  const getDataCollectionStatusText = () => {
    if (!dataCollectionResult) {
      return "Run data collection process to estimate migration costs and download the report";
    }
    if (isRunning) {
      return "Data collection is in progress. Please wait a moment";
    }
    if (dataCollectionResult.status === "failed") {
      return `Data collection initiated on ${date} has failed. Try again later.`;
    }
    return `Data was collected on ${date} by ${dataCollectionResult.initiatorName} (${dataCollectionResult.initiatorLogin})`;
  }

  return (
    <PageLayout message={message} onDismiss={() => setMessage(null)}>
      <GrantingPermissionsDialog
        tenantId={tenantId}
        isOpen={isDialogOpen}
        close={onGrantingClose}
      />
      <AccessDeniedAlert open={isGlobalAdmin === false} reason="Only Global Admins can run data collection"/>
      {isGlobalAdmin && <Card className={styles.card}>
        <Button 
          className={styles.homeButton}
          appearance="subtle"
          icon={<Home20Regular/>}
          onClick={() => navigate('/tab')}/>
        <div className={styles.cardBody}>
          <Text className={styles.explanationText}>
            {getDataCollectionStatusText()}
          </Text>
          <Button 
            className={styles.button}
            appearance="primary"
            size="large"
            icon={isRunning ? <Spinner size="tiny"/> : <PlayRegular/>}
            onClick={onRunAnalysisClick}
          >
            Run data collection
          </Button>
          {hasReport ?
            <Text>
              Current as of {date}.<br/>
              Run data collection to update the estimate
            </Text> : 
            <Text><br/>Run data collection first</Text>}
          <Button
            className={styles.button}
            disabled={!hasReport}
            appearance="primary"
            size="large"
            icon={<CalculatorRegular/>}
            onClick={onEstimateMigrationCostsClick}
          >
            Estimate migration costs
          </Button>
          {hasReport ?
            <Text>
              Current as of {date}.<br/>
              Run data collection to update the report
            </Text> :
            <Text><br/>Run data collection first</Text>}
          <Button
            className={styles.button}
            disabled={!hasReport}
            appearance="primary"
            size="large"
            icon={<ArrowDownloadFilled/>}
            onClick={onDownloadClick}
          >
            Download migration report
          </Button>
        </div>
      </Card>}
    </PageLayout>
  );
}

export default MigrationReportPage;
