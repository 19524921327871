import { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  Text,
  Textarea,
  TextareaOnChangeData,
  makeStyles
} from "@fluentui/react-components";
import { SearchSelect, SelectOption } from "../inputs";

const useStyles = makeStyles({
  title: {
    textAlign: "center"
  },
  content: {
    display: "flex",
    flexDirection: "column"
  },
  textHeader: {
    display: "flex",
    paddingTop: "1em",
    justifyContent: "space-between"
  }
});

export type RedirectDialogProps = {
  open: boolean;
  options: SelectOption[];
  onCancelClick: () => void;
  onProceedClick: (assignedUser: string, comment: string) => void;
};

export const RedirectDialog = (props: RedirectDialogProps) => {
  const [assignedUser, setAssignedUser] = useState("");
  const [comment, setComment] = useState("");

  const onCommentChange = (_: any, data: TextareaOnChangeData) => {
    if (data.value.length <= 500)
      setComment(data.value);
  };

  const styles = useStyles();
  const highlightCounter = comment.length === 500;

  return (
    <Dialog open={props.open}>
      <DialogSurface>
        <DialogBody>
          <DialogTitle className={styles.title}>
            Offer Redirection
          </DialogTitle>
          <DialogContent className={styles.content}>
            <Text>
              Choose an employee from your company who is authorized to make decisions regarding the request.
              If necessary, you can accompany your action with a comment.
            </Text>
            <Text style={{paddingTop: "1em"}}>
              User
            </Text>
            <SearchSelect
              placeholder="Select a user"
              setSelectedOption={(option) => setAssignedUser(option)}
              options={props.options}
              selectedOption={assignedUser}
              />
            <div className={styles.textHeader}>
              <Text>
                Comment
              </Text>
              <Text style={highlightCounter ? {color: "red"} : {}}>
                {comment.length}/500
              </Text>
            </div>
            <Textarea
              appearance="outline"
              placeholder="type here..."
              onChange={onCommentChange}
              value={comment}
              rows={6}
            />
          </DialogContent>
          
          <DialogActions>
            <Button appearance="secondary" onClick={props.onCancelClick}>Cancel</Button>
            <Button appearance="primary" disabled={!assignedUser} onClick={() => props.onProceedClick(assignedUser, comment)}>Proceed</Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  )
}
