import { makeStyles, tokens } from "@fluentui/react-components";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUserLicenses } from '../apis/GraphApi';
import { Communication } from "../components";

const useStyles = makeStyles({
  page: {
    backgroundColor: tokens.colorBrandBackground2,
    display: "flex",
    flexDirection: "row",
    width: "100%",
    minHeight: "100%",
    "@media (max-width: 450px)": {
      flexDirection: "column",
    }
  },
  body: {
    width: "100%",
    minHeight: "100%"
  }
});

export default function LicenseComparisonPage() {
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);
  const [licenses, setLicenses] = useState([] as string[]);

  useEffect(() => {
    getUserLicenses()
      .then(result => {
        setLicenses(result.data.value.map(x => x.skuId));
        setLoaded(true);
      })
      .catch((e) => {
        if (e.code === "ConsentFailed" || e.code === "InternalError")
          navigate("../tab");
        else
          alert(e.message);
      });
  }, [navigate]);

  const styles = useStyles();

  return (
    <div className={styles.page}>
      <Communication/>
      <div className={styles.body}>
        {loaded && <iframe 
          style={{ width: "100%", height: "100%", border: 0 }}
          title="License Comparison View"
          sandbox="allow-forms allow-modals allow-popups allow-popups-to-escape-sandbox allow-pointer-lock allow-scripts allow-same-origin allow-downloads allow-top-navigation-to-custom-protocols"
          src={`https://o365hq.com/srv_identifier_iframe?${licenses.map(id => `guid%5b%5d=${id}`).join('&')}`}>
        </iframe>}
      </div>
    </div>
  );
}
