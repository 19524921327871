import { makeStyles, MessageBarGroup, tokens } from "@fluentui/react-components";
import { MessageInfo } from "../MessageContext";
import { NotificationBar } from "./NotificationBar";

const useStyles = makeStyles({
  notificationBar: {
    minWidth: "500px",
    maxWidth: "100%",
    textAlign: "center",
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)"
  },
  page: {
    backgroundColor: tokens.colorBrandBackground2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100%"
  }
});

export interface FormPageLayoutProps {
  children: any
  message: MessageInfo
  onDismiss: () => void
}

export const PageLayout = (props: FormPageLayoutProps) => {
  const styles = useStyles();

  return (
    <div>
      <MessageBarGroup>
        {props.message && <NotificationBar className={styles.notificationBar} onDismiss={props.onDismiss} {...props.message}/>}
      </MessageBarGroup>
      <div className={styles.page}>
        {props.children}
      </div>
    </div>
  );
}