export type HistoryItem = {
  id: string; // uuid
  requestId: number;
  requestDate: string; // ISO date string
  tenantName: string;
  tenantId: string;
  requester: string;
  requestText: string;
  offer: string;
  status: string;
  statusName: string;
  decisionMakerComment: string;
  decisionMaker: string;
  decisionDate: string; // ISO date string
};

export type OfferDto = {
  id: string; // uuid
  tenantId: string;
  initiatorName: string;
  initiatorEmail: string;
  requestDate: string;
  requestText: string;
  offerDate?: string;
  offerText?: string;
  status: OfferStatus;
  decisionMakers?: string[];
  decisionMakerName?: string;
  decisionMakerEmail?: string;
  decisionDate?: string;
  redirectedFrom?: string;
  redirectComment?: string;
}

export type RedirectOfferDto = {
  assignTo: string,
  comment?: string
};

export type IdNameDto = {
  id: string;
  name: string;
};

export enum OfferStatus {
  submitted = "Submitted",
  waitingForDecision = "WaitingForDecision",
  approved = "Approved",
  declined = "Declined",
  redirected = "Redirected"
}

export type UserRole = "user" | "globalAdmin" | "itpEngineer";