import { Button, Card, makeStyles, MessageBarGroup, shorthands, Spinner, Text } from "@fluentui/react-components";
import { Home20Regular, PlayRegular } from "@fluentui/react-icons";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useIsGlobalAdmin } from "../apis/GraphApi";
import { useGetUserInfo, useHasLicenseOptimizerPermissions } from "../apis/TenantOptimizerApi";
import { NotificationBar } from "../components";
import { AccessDeniedAlert, AnalyticsRequestDialog, TenantNotRegisteredAlert } from "../components/dialogs";
import config from "../config";

const useOverrides = makeStyles({
  page: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100%"
  },
  messageBar: {
    minWidth: "740px"
  },
  card: {
    width: "740px",
    alignItems: 'center',
    ...shorthands.margin("auto", 0)
  },
  homeButton: {
    marginLeft: 'auto'
  },
  descriptionText: {
    marginTop: '-20px',
    color: '#616161',
    fontSize: '18px',
    padding: '10px',
    lineHeight: '1.5',
    textAlign: 'justify',
  },
  mainButton: {
    fontSize: "24px",
    fontWeight: "normal",
    width: '360px',
    height: '60px',
    ...shorthands.margin("30px", 0)
  },
  alphaText: {
    textAlign: 'justify',
    marginTop: '16px',
    color: '#979797',
    fontSize: '15px',
    padding: '10px',
    lineHeight: '1.5',
  }
});

const TenantOptimizerPage = () => {
  const styles = useOverrides();
  const navigate = useNavigate();
  const [isAnalyticsDialogOpen, setIsAnalyticsDialogOpen] = useState(false);
  const [isTenantNotRegisteredAlertOpen, setIsTenantNotRegisteredAlertOpen] = useState(false);
  const [isTenantSuccessfullyRegisteredMessageBarOpen, setIsTenantSuccessfullyRegisteredMessageBarOpen] = useState(false);
  const { isFetching: isTenantOptimizerServiceRegisteredFetching, data: isTenantOptimizerServiceRegisteredData } = useHasLicenseOptimizerPermissions();

  const { data: isGlobalAdmin, isFetching } = useIsGlobalAdmin();
  const { data: user, isFetching: isUserFetching } = useGetUserInfo();

  if (isUserFetching) {
    return (
      <div className={styles.page}>
        <Spinner/>
      </div>
    );
  }

  return (
    <div className={styles.page}>
      <AccessDeniedAlert
        reason={"Only Global Admins can request an analytical report."}
        open={!isFetching && !isGlobalAdmin}
      />
      <TenantNotRegisteredAlert
        isOpen={isTenantNotRegisteredAlertOpen}
        close={() => {
          setIsTenantNotRegisteredAlertOpen(false);
        }}
        onGrantSuccess={() => {
          setIsTenantSuccessfullyRegisteredMessageBarOpen(true);
        }}
        clientId={config.tenantOptimizerClientId}
        redirectUri={config.tenantOptimizerBackendUrl + "/GrantingCallback"}
      />
      <MessageBarGroup>
        <div className={styles.messageBar}>
          {isTenantSuccessfullyRegisteredMessageBarOpen &&
          <NotificationBar 
            title="Tenant registered successfully"
            intent={"success"}
            onDismiss={() => setIsTenantSuccessfullyRegisteredMessageBarOpen(false)}
          />}
        </div>
      </MessageBarGroup>
      {isGlobalAdmin && <Card className={styles.card}>
        <Button 
          className={styles.homeButton}
          appearance="subtle"
          size="large"
          icon={<Home20Regular/>}
          onClick={() => navigate('/tab')}/>
        <Text className={styles.descriptionText}>
          To analyze your tenant and prepare a report, click 'Request an
          Analytical Report' button. After the analysis is completed, the
          report will be generated and sent to your email{" "}
          {user.userPrincipalName}.
        </Text>
        <Button
          disabled={isTenantOptimizerServiceRegisteredFetching || isFetching}
          className={styles.mainButton}
          appearance="primary"
          icon={<PlayRegular/>}
          onClick={async () => {
            if (isTenantOptimizerServiceRegisteredData?.HasPermissions) {
              setIsAnalyticsDialogOpen(true);
            } else {
              setIsTenantNotRegisteredAlertOpen(true);
            }
          }}
        >
          Request an Analytical Report
        </Button>
        <Text className={styles.alphaText}>
          This is the alpha version. We're actively testing design, enhancing functionality,
          and system performance. Our goal is high quality and accurate data,
          but reports and recommendations may contain errors.Please report any issues!
        </Text>
        <AnalyticsRequestDialog
          isOpen={isAnalyticsDialogOpen}
          setDialogOpen={setIsAnalyticsDialogOpen}
        />
      </Card>}
    </div>
  );
};

export default TenantOptimizerPage;

