import {
  makeStyles,
  shorthands,
  MessageBarGroup,
  MessageBar,
  MessageBarBody,
  MessageBarTitle,
  MessageBarActions,
  Button
} from "@fluentui/react-components";
import { DismissRegular } from "@fluentui/react-icons";
import { useState, useEffect } from "react";
import { MessageContext, MessageBarIntentMapping, MessageInfo } from "../MessageContext";
import { loadInfoboard } from "../apis/CustomerManagementApi";

type MessageInfoDto = {
  type: string
  header: string
  body: string
}

const useStyles = makeStyles({
  globalAdminMessages: {
    display: "flex",
    flexDirection: "column",
    "& > *": {
      ...shorthands.margin("3px")
    }
  }
});

export const Infoboard = () => {
  const [infoboardMessages, setInfoboardMessages] = useState(MessageContext.infoboardMessages);

  useEffect(() => {
    loadInfoboard().then((response) => {
      const messages = response.data as MessageInfoDto[];
      const mappedMessages = messages.map((message) => {
        return {
          title: message.header,
          description: message.body,
          intent: MessageBarIntentMapping[message.type]
        } as MessageInfo;
      });
      setInfoboardMessages(mappedMessages);
    })
    .catch((error) => {
      console.error(error);
    });
  }, []);
  
  const styles = useStyles();
  return (
    <MessageBarGroup>
      <div className={styles.globalAdminMessages}>
        {infoboardMessages.map((message, index) => <MessageBar
          key={index}
          layout="multiline"
          intent={message.intent}>
          <MessageBarBody>
            <MessageBarTitle>{message.title}</MessageBarTitle>
            {message.description}
          </MessageBarBody>
          <MessageBarActions
            containerAction={
              <Button
                aria-label="dismiss"
                appearance="transparent"
                icon={<DismissRegular />}
                onClick={() => {
                  setInfoboardMessages(infoboardMessages.filter((_, i) => i !== index));
                }}
              />
            }
          >
          </MessageBarActions>
        </MessageBar>)}
      </div>
    </MessageBarGroup>
  );
}