import { useContext } from "react";
import { Button, FluentProvider, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { OpenRegular } from '@fluentui/react-icons';
import { TeamsFxContext } from "../Context";
import { app } from "@microsoft/teams-js";

const useStyles = makeStyles({
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    backgroundColor: tokens.colorBrandStroke2,
    minHeight: "100%"
  },
  button: {
    ...shorthands.margin("0px", "2px", "2px"),
    backgroundColor: tokens.colorBrandBackground2
  },
  openIcon: {
    marginLeft: "5px"
  }
});

export const Communication = () => {
  const styles = useStyles();
  const { theme } = useContext(TeamsFxContext);
  return (
    <FluentProvider theme={{
      ...theme,
      colorNeutralBackground1Hover: tokens.colorBrandBackground2Hover,
      colorNeutralBackground1Pressed: tokens.colorBrandBackground2Pressed
    }}>
      <div className={styles.buttonContainer}>
        <h2>Communication channels</h2>
        <Button className={styles.button} as="a" href="https://qr.o365hq.com/ITPAssist-helpdesk" target="_blank">
          ITP Helpdesk Portal<OpenRegular className={styles.openIcon}/>
        </Button>
        <Button className={styles.button} as="a" href="mailto:sales@o365hq.com">
          Email us<OpenRegular className={styles.openIcon}/>
        </Button>
        <Button className={styles.button} as="a" href="tel:+18557000365" target="_blank">
          Call us<OpenRegular className={styles.openIcon}/>
        </Button>
        <Button className={styles.button} as="a" href="https://o365hq.com/pages/request-callback" target="_blank">
          Request a call back<OpenRegular className={styles.openIcon}/>
        </Button>
        <Button className={styles.button} as="a" href="https://qr.o365hq.com/ITPAssist-schedule-meeting" target="_blank">
          Schedule a meeting<OpenRegular className={styles.openIcon}/>
        </Button>
        <Button className={styles.button} onClick={() => app.openLink("https://teams.microsoft.com/l/chat/0/0?users=mym@itpartner365.com")}>
          Chat in Teams
        </Button>
      </div>
    </FluentProvider>
  );
}
