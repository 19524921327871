import {
  makeStyles,
  shorthands,
  Button,
  PopoverTrigger,
  PopoverSurface,
  Popover
} from "@fluentui/react-components";
import { ArrowDownloadFilled, ChevronDown16Filled , DocumentTableRegular, DocumentPdfRegular } from "@fluentui/react-icons";

const useStyles = makeStyles({
  mainButton: {
    borderTopRightRadius: "0px",
    borderBottomRightRadius: "0px",
    marginRight: "1px"
  },
  optionsButton: {
    borderTopLeftRadius: "0px",
    borderBottomLeftRadius: "0px",
    minWidth: "24px",
    maxWidth: "24px",
  },
  popover: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.padding("5px", "0px"),
  },
  menuButton: {
    justifyContent: "left"
  }
});

export type DownloadReportButtonProps = {
  className: string;
  onDownloadClick: (format: 'excel' | 'pdf') => void;
}

export const DownloadReportButton = ({ className, ...props }: DownloadReportButtonProps) => {
  const styles = useStyles();
  return (
    <div className={className}>
      <Button
        className={styles.mainButton}
        appearance="primary"
        icon={<ArrowDownloadFilled />}
        onClick={() => props.onDownloadClick('excel')}
      >
        Export
      </Button>
      <Popover positioning="below-end">
        <PopoverTrigger disableButtonEnhancement>
          <Button
            className={styles.optionsButton}
            appearance="primary"
            icon={<ChevronDown16Filled  />}
            aria-label="More options"
          />
        </PopoverTrigger>
        <PopoverSurface className={styles.popover}>
          <Button
            className={styles.menuButton}
            appearance="subtle"
            icon={<DocumentTableRegular />}
            onClick={() => props.onDownloadClick('excel')}
          >
            Export to Excel
          </Button>
          <Button
            className={styles.menuButton}
            appearance="subtle"
            icon={<DocumentPdfRegular />}
            onClick={() => props.onDownloadClick('pdf')}
          >
            Export to PDF
          </Button>
        </PopoverSurface>
      </Popover>
    </div>
  )
}