import { TeamsUserCredential } from "@microsoft/teamsfx";
import config from "../config";
import { app } from "@microsoft/teams-js";

export const Auth = {
  getToken: async (scopes: string | string[], claims?: string) => {
    const credential = Auth.getCredential();
    return await credential.getToken(scopes, { claims });
  },
  getUserInfo: async () => {
    const context: app.Context = await app.getContext();
    return context.user;
  },
  getCredential: () => {
    return new TeamsUserCredential({
      initiateLoginEndpoint: config.initiateLoginEndpoint!,
      clientId: config.clientId!,
    });
  }
}