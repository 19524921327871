import axios, { AxiosError } from "axios";
import { UseMutationResult, UseQueryResult, useMutation, useQuery, useQueryClient } from "react-query";
import { TenantAnalysis } from "../models/MigrationReport";
import { Auth } from "../utils/auth";
import config from "../config";

export const useGetDataCollectionResult = (enabled: boolean, onError?: (error: AxiosError) => void) : UseQueryResult<TenantAnalysis, unknown> => {
  return useQuery({
    queryKey: ["dataCollectionResult"],
    queryFn: async () => {
      const token = await Auth.getToken([]);
      const response = await axios.get<TenantAnalysis>(`${config.migrationReportsBackendUrl}/report`, {
        headers: {
          Authorization: `Bearer ${token.token}`
        }
      });
      return response.data;
    },
    onError: onError,
    retry: false,
    enabled: enabled,
    staleTime: 1000
  });
}

export const useRunDataCollection = (onError: (error: AxiosError) => void) : UseMutationResult<TenantAnalysis, AxiosError, string> => {
  const queryClient = useQueryClient();
  return useMutation<TenantAnalysis, AxiosError, string>({
    mutationFn: async () => {
      const token = await Auth.getToken([]);
      const response = await axios({
        method: "POST",
        url: `${config.migrationReportsBackendUrl}/report/start`, 
        headers: {
          Authorization: `Bearer ${token.token}`
        }
      });
      return response.data;
    },
    onSuccess: (data) => {
      queryClient.setQueryData(["dataCollectionResult"], data)
    },
    onError: onError
  })
}

export const getExcelReport = async (reportId: string) => {
  const token = await Auth.getToken([]);
  const response = await axios.get(`${config.migrationReportsBackendUrl}/report/excel?reportId=${reportId}`, {
    headers: {
      Authorization: `Bearer ${token.token}`
    },
    responseType: "blob"
  });
  return response;
}