import { MessageBarIntent } from "@fluentui/react-components";

export type MessageInfo = {
  intent: MessageBarIntent
  title?: string
  description: string
}

export const MessageBarIntentMapping: { [key: string]: MessageBarIntent } = {
  Alert: 'error',
  Warning : 'warning',
  Notification : 'info'
}

export const MessageContext: {
  infoboardMessages: MessageInfo[];
  message: MessageInfo
} = {
  message: null,
  infoboardMessages: []
};
