import { Button, makeStyles, mergeClasses, shorthands } from "@fluentui/react-components";

const useStyles = makeStyles({
  button: {
    color: "#237B4B",
    backgroundColor: "#E7F2DA",
    ...shorthands.borderColor("#BDDA9B"),
    ":hover": {
      color: "#1E6A3F",
      backgroundColor: "#D9EBC8",
      ...shorthands.borderColor("#AFCB8A"),
    },
    ":hover:active": {
      color: "#1A5C36",
      backgroundColor: "#C2D9A6",
      ...shorthands.borderColor("#9BB97A"),
    }
  }
});

type ApproveButtonProps = {
  className: string;
  onClick: () => void;
}

export const ApproveButton = (props: ApproveButtonProps) => {
  const styles = useStyles();
  return (
    <Button
      className={mergeClasses(props.className, styles.button)}
      onClick={() => props.onClick()}
    >
      Approve
    </Button>
  );
}