import { useState } from "react";
import { AnalyticsRequestDialogProps } from "./AnalyticalDialogProps";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  Label,
  Spinner,
  Text,
  Tooltip
} from "@fluentui/react-components";
import { AnalyticsRequestSuccessDialog } from "../AnalyticsRequestSuccessDialog/AnalyticsRequestSuccessDialog";
import { dateTimeFormatOptions } from "../../../utils/DateTimeFormatOptions";
import { useCreateAnalyticsRequestMutation, useGetLastCustomerAnalytics, useGetUserInfo } from "../../../apis/TenantOptimizerApi";

export const AnalyticsRequestDialog = (props: AnalyticsRequestDialogProps) => {
  const { isOpen, setDialogOpen } = props;
  const [ isSuccessDialogOpen, setSuccessDialogOpen] = useState(false);
  const [ isReceiveMonthlyReportsChecked , setIsReceiveMonthlyReportsChecked] = useState(false);
  
  const { data: user, isSuccess } = useGetUserInfo();
  const tenantId = user?.tenant.id || "";
  const mutation = useCreateAnalyticsRequestMutation(() => {
    setDialogOpen(false);
    setSuccessDialogOpen(true);
  });
  
  const { isLoading, error, data: lastCustomerAnalytics } = useGetLastCustomerAnalytics(tenantId, isSuccess);  

  const onConfirmClick = () => {
    if (user != null && user.displayName != null) {
      mutation.mutate({
        TenantId: tenantId,
        InitiatorName: user.displayName,
        InitiatorEmail: user.userPrincipalName,
        ReceiveMonthlyReports: isReceiveMonthlyReportsChecked,
      });
    } else {
      console.error(
        "account or account.name is null"
      );
    }
  };

  const date = new Date(lastCustomerAnalytics?.RequestDate || "");
  const text = "The last request for your tenant was made on " + date.toLocaleString('en-US', dateTimeFormatOptions) + " by " + lastCustomerAnalytics?.InitiatorName + " (" + lastCustomerAnalytics?.InitiatorEmail + ").";
  return (
    <>
      <Dialog modalType="alert" open={isOpen}>
        <DialogSurface>
          <DialogBody>
            <DialogTitle>Analytical Report Request</DialogTitle>
            <DialogContent>
              { lastCustomerAnalytics != null ? (
                <>
                <Text block={true}>
                  {text}
                </Text>
                <Checkbox
                  label={(
                    <Tooltip content="Reports will be sent on the first day of each month" relationship='label'>
                      <Label>
                        Receive monthly reports
                      </Label>
                    </Tooltip>
                  )}
                  defaultChecked={lastCustomerAnalytics?.ReceiveMonthlyReports || false}
                  onChange={(_, checked) => {
                    if (typeof checked.checked == "boolean") {
                      setIsReceiveMonthlyReportsChecked(checked.checked);
                    }
                  }}
                />
              </>) : <Spinner/>}
            </DialogContent>
            <DialogActions>
              <Button
                appearance="secondary"
                onClick={() => {
                  setDialogOpen(false);
                }}
              >
                Cancel
              </Button>
              <Button
                disabled={isLoading || error != null}
                appearance="primary"
                onClick={onConfirmClick}
              >
                Confirm
              </Button>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
      <AnalyticsRequestSuccessDialog
        isOpen={isSuccessDialogOpen}
        setDialogOpen={setSuccessDialogOpen}
      />
    </>
  );
};
