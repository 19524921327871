import { AccessToken } from '@azure/identity';
import axios from "axios";
import jwt_decode from "jwt-decode";
import { useQuery, UseQueryResult } from "react-query";
import { Auth } from "../utils/auth";

type UserData = {
  displayName: string,
  userPrincipalName: string
}

type LicenseDetailsResponse = {
  value: {skuId: string}[]
}

export const useIsGlobalAdmin = (): UseQueryResult<boolean, unknown> => {
  const query = useQuery("isGlobalAdmin", async () => {
    var tokenAnswer = await Auth.getToken([]);
    const payload = jwt_decode<{wids: string[]}>(tokenAnswer.token);
    return payload.wids.includes('62e90394-69f5-4237-9190-012177145e10');
  }, { staleTime: 60_000 });
  return query;
}

export const getUserLicenses = async() => {
  const credential = Auth.getCredential();
  let token: AccessToken;
  const scopes = ['User.Read'];
  try {
    token = await credential.getToken(scopes);
  }
  catch(err) {
    await credential.login(scopes);
    token = await credential.getToken(scopes);
  }

  return await axios.get<LicenseDetailsResponse>("https://graph.microsoft.com/v1.0/me/licenseDetails?$select=skuId", {
    headers: {
      Authorization: `Bearer ${token.token}`
    }
  });
}

// TODO: rewrite to use Graph API
export const getTenantUsers = async (): Promise<UserData[]> => {
  const token = await Auth.getToken([]);
  const result = await axios.get<UserData[]>("/users", {
    headers: {
      Authorization: `Bearer ${token.token}`
    }
  });
  return result.data;
}