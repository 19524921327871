import { Button, ButtonProps, Tooltip } from "@fluentui/react-components";
import { OpenRegular } from '@fluentui/react-icons';

export const NavigateButton = (props: ButtonProps) => {
  return (
    <Tooltip content="Go to decision-making screen" relationship="label">
      <Button 
        icon={<OpenRegular/>}
        {...props}/>
    </Tooltip>
  );
}