import axios from "axios";
import { HistoryItem, OfferDto, RedirectOfferDto, UserRole } from "../models";
import { Auth } from "../utils/auth";

export const sendRequest = async (offerId: string, requestText: string) => {
  const token = await Auth.getToken([]);
  await axios({
    method: 'post',
    url: 'offers/submit',
    headers: {
      Authorization: `Bearer ${token.token}`
    },
    data: {
      offerId: offerId,
      requestText: requestText,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
    }
  });
}

export const sendOffer = async (offerId: string, text: string) => {
  const token = await Auth.getToken([]);
  await axios({
    method: 'post',
    url: `/offers/${offerId}/send`,
    headers: {
      Authorization: `Bearer ${token.token}`
    },
    data: {
      offerId: offerId,
      offerText: text
    }
  });
}

export const getOffer = async (offerId: string) => {
  const token = await Auth.getToken([]);
  return await axios.get<OfferDto>(`offers/${offerId}`, {
    headers: {
      Authorization: `Bearer ${token.token}`
    }
  })
};

export const approveOffer = (offerId: string, comment?: string) => 
  updateOfferStatus(offerId, "approve", comment);

export const declineOffer = (offerId: string, comment?: string) =>
  updateOfferStatus(offerId, "decline", comment);

const updateOfferStatus = async (offerId: string, action: string, comment?: string) => {
  const token = await Auth.getToken([]);
  await axios({
    method: 'post',
    url: `offers/${offerId}/${action}`,
    headers: {
      Authorization: `Bearer ${token.token}`
    },
    data: {
      comment: comment
    }
  });
}

export const redirectOffer = async (offerId: string, dto: RedirectOfferDto) => {
  const token = await Auth.getToken([]);
  await axios({
    method: 'post',
    url: `offers/${offerId}/redirect`,
    headers: {
      Authorization: `Bearer ${token.token}`
    },
    data: dto
  });
}

const getHistoryEndpoint = (userRole: UserRole) => {
  switch (userRole) {
    case "user":
      return "initiator";
    case "globalAdmin":
      return "tenant";
    case "itpEngineer":
      return "all";
  }
}

export type HistoryItemsRequestParams = {
  userRole: UserRole;
  offerId?: string;
  tenantId?: string;
  fromDate?: Date;
  toDate?: Date;
  statuses?: string[];
}

export const getHistoryItems = async (requestParams: HistoryItemsRequestParams) => {
  const token = await Auth.getToken([]);
  
  const queryParams = [];
  if (requestParams.offerId) {
    queryParams.push(`offerId=${requestParams.offerId}`);
  }
  if (requestParams.tenantId) {
    queryParams.push(`tenantId=${requestParams.tenantId}`);
  }
  if (requestParams.fromDate !== null) {
    queryParams.push(`from=${requestParams.fromDate.toISOString()}`);
  }
  if (requestParams.toDate !== null) {
    const toDate = new Date(requestParams.toDate);
    toDate.setHours(23, 59, 59, 999);
    queryParams.push(`to=${toDate.toISOString()}`);
  }
  if (requestParams.statuses && requestParams.statuses.length > 0) {
    requestParams.statuses.forEach(st => {
      queryParams.push(`status=${encodeURIComponent(st)}`);
    });
  }
  const queryString = queryParams.join('&');
  const url = `/history/${getHistoryEndpoint(requestParams.userRole)}?${queryString}`;
  
  return await axios.get<HistoryItem[]>(url, {
    headers: {
      Authorization: `Bearer ${token.token}`
    }
  });
}

export type UserRoleDto = {
  role: UserRole;
};

export const getUserRole = async () => {
  const token = await Auth.getToken([]);
  return await axios.get<UserRoleDto>('/users/role', {
    headers: {
      Authorization: `Bearer ${token.token}`
    }
  });
};

export type HistoryReportRequestParams = {
  userRole: UserRole;
  format: string;
  tenantId?: string;
  fromDate?: Date;
  toDate?: Date;
  statuses?: string[];
}

export const getHistoryReport = async (requestParams: HistoryReportRequestParams) => {
  const token = await Auth.getToken([]);

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const queryParams = [`timeZone=${timeZone}`, `format=${requestParams.format}`];
  if (requestParams.tenantId) {
    queryParams.push(`tenantId=${requestParams.tenantId}`);
  }
  if (requestParams.fromDate !== null) {
    queryParams.push(`from=${requestParams.fromDate.toISOString()}`);
  }
  if (requestParams.toDate !== null) {
    const toDate = new Date(requestParams.toDate);
    toDate.setHours(23, 59, 59, 999);
    queryParams.push(`to=${toDate.toISOString()}`);
  }
  if (requestParams.statuses && requestParams.statuses.length > 0) {
    requestParams.statuses.forEach(st => {
      queryParams.push(`status=${encodeURIComponent(st)}`);
    });
  }
  const queryString = queryParams.join('&');
  const url = `/history/export/${getHistoryEndpoint(requestParams.userRole)}?${queryString}`;

  return await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token.token}`
    },
    responseType: "blob" // important
  });
}