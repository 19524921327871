import { Button, makeStyles, mergeClasses, shorthands } from "@fluentui/react-components";

const useStyles = makeStyles({
  button: {
    color: "#C4314B",
    backgroundColor: "#FCF4F6",
    ...shorthands.borderColor("#F3D6D8"),
    ":hover": {
      color: "#AD2C42",
      backgroundColor: "#F9E8EB",
      ...shorthands.borderColor("#E8BFC2"),
    },
    ":hover:active": {
      color: "#972637",
      backgroundColor: "#F4D1D6",
      ...shorthands.borderColor("#D8A3A7"),
    }
  }
});

type DeclineButtonProps = {
  className: string;
  onClick: () => void;
}

export const DeclineButton = (props: DeclineButtonProps) => {
  const styles = useStyles();
  return (
    <Button
      className={mergeClasses(props.className, styles.button)}
      onClick={() => props.onClick()}
    >
      Decline
    </Button>
  );
}