import { Button, makeStyles, mergeClasses, shorthands } from "@fluentui/react-components";

const useStyles = makeStyles({
  button: {
    backgroundColor: "#EBEBEB",
    ...shorthands.borderColor("#D1D1D1"),
    ":hover": {
      backgroundColor: "#DADADA",
      ...shorthands.borderColor("#BEBEBE"),
    },
    ":hover:active": {
      backgroundColor: "#C4C4C4",
      ...shorthands.borderColor("#A8A8A8"),
    }
  }
});

type RedirectButtonProps = {
  className: string;
  onClick: () => void;
}

export const RedirectButton = (props: RedirectButtonProps) => {
  const styles = useStyles();
  return (
    <Button
      className={mergeClasses(props.className, styles.button)}
      onClick={() => props.onClick()}
    >
      Redirect
    </Button>
  );
}