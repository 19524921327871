import { Combobox, ComboboxProps, Option } from "@fluentui/react-components";
import { useEffect, useState } from "react";

export type SelectOption = {
  name: string
  value: string
}

export interface SearchSelectProps extends ComboboxProps {
  options: SelectOption[];
  setSelectedOption: (option: string) => void;
  selectedOption: string;
}

export const SearchSelect = (props: SearchSelectProps) => {
  const [value, setValue] = useState("");
  const [matchingOptions, setMatchingOptions] = useState([] as SelectOption[]);

  useEffect(() => {
    const option = props.options.find(o => o.value === props.selectedOption);
    setValue(option?.name ?? "");
  }, [props.selectedOption, props.options]);

  useEffect(() => {
    setMatchingOptions([...props.options]);
  }, [props.options]);

  const onOptionSelect: (typeof props)["onOptionSelect"] = (ev, data) => {
    props.setSelectedOption(data.optionValue);
    setValue(data.optionText ?? "");
  };

  const onBlur = () => {
    if (!props.selectedOption) {
      setValue("");
      setMatchingOptions([...props.options]);
    }
  };

  const onInput = (ev: React.ChangeEvent<HTMLInputElement>) => {
    var lowerInput = ev.target.value.trim().toLowerCase();
    const matches = props.options.filter(option => 
      option.name.toLowerCase().includes(lowerInput)
    );
    setMatchingOptions(matches);
    setValue(ev.target.value);
  };

  return (
    <Combobox freeform
      value={value}
      onInput={onInput}
      onBlur={onBlur}
      onOptionSelect={onOptionSelect}
      selectedOptions={props.selectedOption ? [props.selectedOption] : []}
      {...props}>
      {matchingOptions.map((option) => (
        <Option key={option.value} value={option.value}>
          {option.name}
        </Option>
      ))}
    </Combobox>
  )
}